import { Component, OnInit, ViewChild } from '@angular/core';
import { DealersService } from '../services/dealers.service';
import { CountryList } from '../model/CountryList.model';
import { TegelModule } from '@scania/tegel-angular-17';
import { UtilsService } from '../utils/utils.service';
import { CommonModule } from '@angular/common';
import { OpeningHoursComponent } from '../common/opening-hours/opening-hours.component';
import { FaxPhoneComponent } from '../common/fax-phone/fax-phone.component';
import { ExportService } from '../services/export.service';

@Component({
  selector: 'app-dealers-workshops',
  standalone: true,
  imports: [
    TegelModule,
    CommonModule,
    OpeningHoursComponent,
    FaxPhoneComponent,
  ],
  templateUrl: './dealers-workshops.component.html',
  styleUrl: './dealers-workshops.component.css',
})
export class DealersWorkshopsComponent implements OnInit {
  @ViewChild('countryRef', { static: true })
  countryRef!: HTMLTdsDropdownElement;
  @ViewChild('cityRef', { static: true })
  cityRef!: HTMLTdsDropdownElement;
  @ViewChild('serviceRef', { static: true })
  serviceRef!: HTMLTdsDropdownElement;
  @ViewChild('companyRef', { static: true })
  companyRef!: HTMLTdsDropdownElement;
  @ViewChild('dealerWorkshopRef', { static: true })
  dealerWorkshopRef!: HTMLTdsDropdownElement;

  cityList: any[] = [];
  companyNameList: any[] = [];
  serviceList: any[] = [];
  selectedItems: any[] = [];
  dealersDetailList: any[] = [];
  noResult: boolean = false;
  selectedServices: string[] = [];
  isDataLoaded: boolean = false;
  selectedtype: string = '';
  selectedValues: any = [];
  enableExport = false;
  selectedDetails: boolean = false;
  dealer: any;

  constructor(
    private dealersService: DealersService,
    private utilServices: UtilsService,
    private exportServices: ExportService
  ) {}
  countryLists: CountryList[] = [];
  dealerDetailsArr: any[] = [];
  phoneFax: any[] = [
    {
      title: '',
    },
  ];

  ngOnInit() {
    this.dealersService.getDealersCountry().subscribe((res: any) => {
      if (res && res?.DealerCountry) {
        this.countryLists = res.DealerCountry;
        this.countryLists.sort((a, b) =>
          a.CountryName.localeCompare(b.CountryName)
        );
      } else {
        this.countryLists = [];
      }
    });
  }

  changeCountry(columnName: string, countryCode: string) {
    if (countryCode == '') {
      this.enableExport = false;

      this.noResult = true;
      this.cityRef.reset();
      this.cityList = [];
      this.companyRef.reset();
      this.companyNameList = [];
      this.serviceRef.reset();
      this.serviceList = [];

      return;
    } else {
      this.noResult = false;
      this.enableExport = true;
      this.selectedValues[columnName] = countryCode;
    }

    this.dealersService
      .getDealerDetailsByCountry(countryCode)
      .subscribe((res: any) => {
        console.log(res);

        if (res && res.length > 0) {
          this.isDataLoaded = true;
          this.dealerDetailsArr = res;

          this.dealersDetailList = this.dealerDetailsArr;
          this.dealer = this.dealersDetailList[0];
          setTimeout(() => {
            this.dealerWorkshopRef.setValue(this.dealer?.ScaniaId);
          }, 200);

          this.populateCities(this.dealerDetailsArr);
          this.cityRef.reset();
          this.populateCompanies(this.dealerDetailsArr);
          this.companyRef.reset();
          this.populateServices(this.dealerDetailsArr);
          this.serviceRef.reset();
        } else {
          this.isDataLoaded = false;
        }
      });
  }

  populateCities(dealers: any) {
    this.cityList = [];

    this.cityList = [
      ...new Set(
        dealers.map(
          (element: any) =>
            element?.LegalAddress?.PostalAddress?.PhysicalAddress?.City?.Value
        )
      ),
    ].sort();
  }

  populateCompanies(dealers: any) {
    this.companyNameList = [];

    this.companyNameList = [
      ...new Set(
        dealers.map(
          (element: any) => element?.OrganizationName?.LegalName?.Value
        )
      ),
    ].sort();
  }

  populateServices(dealers: any) {
    this.serviceList = [];
    this.serviceList = this.utilServices
      .get_unique_object_array(
        dealers.flatMap((element: any) => element?.ProvidedServices),
        'DealerServiceName'
      )
      .sort();
    //this.serviceList =
  }

  getDealearsByCity(columnName: string, cityName: string) {
    if (cityName && cityName !== '') {
      this.dealerDetailsArr = this.dealersDetailList.filter(
        (element) =>
          element?.LegalAddress?.PostalAddress?.PhysicalAddress?.City?.Value ==
          cityName
      );
      this.selectedValues[columnName] = cityName;
    } else {
      this.dealerDetailsArr = this.dealersDetailList;
    }
    this.dealer = this.dealerDetailsArr[0];
    this.populateCompanies(this.dealerDetailsArr);
    this.populateServices(this.dealerDetailsArr);
  }

  getDealersByName(columnName: string, CompanyName: string) {
    if (CompanyName && CompanyName !== '') {
      this.dealerDetailsArr = this.dealerDetailsArr.filter(
        (element) => element?.OrganizationName?.LegalName?.Value == CompanyName
      );

      this.selectedValues[columnName] = CompanyName;

      //  this.selectedValues[2] = CompanyName
    } else {
      this.dealerDetailsArr = this.dealersDetailList;
    }

    this.dealer = this.dealerDetailsArr[0];
    this.populateCities(this.dealerDetailsArr);
    this.populateServices(this.dealerDetailsArr);
  }

  getDealerByService(services: string) {
    if (services) {
      this.selectedItems = services.split(',');

      this.dealerDetailsArr = this.dealersDetailList.filter((element) =>
        this.onSelectMultiple(element?.ProvidedServices, this.selectedItems)
      );
    } else {
      this.selectedItems = [];
      this.dealerDetailsArr = this.dealersDetailList;
    }
    this.dealer = this.dealerDetailsArr[0];
    this.populateCities(this.dealerDetailsArr);
    this.populateCompanies(this.dealerDetailsArr);
  }

  onSelectMultiple(dealerProvideServices: any[], selectedItems: any[]) {
    let servicelist: any[] = dealerProvideServices.map(
      (dealerService: any) => dealerService?.DealerServiceName?.replaceAll(',','')
    );
    //let filterServices: string = selectedItems.map((service: any) => service);
    if (selectedItems.every((item: any) => servicelist.includes(item))) {
      return true;
    }
    return false;
  }

  removeService(index: number, service: string) {
    if (index > -1) {
      this.selectedItems.splice(index, 1);
      this.serviceRef.removeValue(service);
      if (this.selectedItems.length) {
        this.dealerDetailsArr = this.dealersDetailList.filter((element) =>
          this.onSelectMultiple(element?.ProvidedServices, this.selectedItems)
        );
      } else {
        this.dealerDetailsArr = this.dealersDetailList;
      }
      this.populateCities(this.dealerDetailsArr);
      this.populateCompanies(this.dealerDetailsArr);
    }
  }

  showDetails(scaniaId: string) {
    let data = this.dealerDetailsArr.find(
      (item: any) => item.ScaniaId === scaniaId
    );
    if (data) {
      this.dealer = data;
      this.selectedDetails = true;
    }
    if (window.innerWidth > 992)
      window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  isSelected(data: any) {
    return this.dealer.ScaniaId === data.ScaniaId;
  }

  async export(type: any) {
    //let type = event?.detail?.value;
    let id: any = await this.exportServices.fetchCateegoryDataByName(
      'DealerWorkshops'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportServices.exportData(request).subscribe((res: any) => {
      if (res != undefined) {
        let blob: Blob = res.body as Blob;
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        if (type === 'excel') {
          a.download =
            'sisexport-dealerworkshop-' + this.getDateFormat() + '.xlsx';
        } else {
          a.download =
            'sisexport-dealerworkshop-' + this.getDateFormat() + '.' + type;
        }
        a.href = url;
        a.click();
        //this.selectedValues = [];
      }
    });
  }

  async exportfortest(event: any) {
    let type = event?.detail?.value;
    let id: any = await this.exportServices.fetchCateegoryDataByName(
      'DealerWorkshops'
    )?.Id;
    this.createExportFilterQuery();
    const request = {
      type: type,
      categoryid: id,
      filterData: JSON.stringify(this.createExportFilterQuery()),
    };
    this.exportServices.exportData(request).subscribe((res: any) => {});
  }

  getDateFormat() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const formattedDate = `${year}${month}${day}`;
    return formattedDate;
  }
  createExportFilterQuery() {
    let reqarray = [];
    if (this.selectedValues) {
      for (let key in this.selectedValues) {
        let obj = { field: '', op: 'bw', data: '' };
        obj.field = key;
        obj.data = this.selectedValues[key];
        reqarray.push(obj);
      }
    }
    return reqarray;
  }

  getUrl(url: string) {
    return this.utilServices.getUrl(url);
  }
}

export interface DropdownEvent {
  name: string;
  value: string;
}
